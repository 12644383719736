<template>
  <div>
    <div class="help_box" draggable="true" @dragend='dragend' v-show="helpShow"
         :style="{'top':top+'px','left':left+'px'}">
      <div class="header">
        帮助文档
        <div class="close">
          <i class="el-icon-close" @click="closeBtn"></i>
        </div>

      </div>
      <div>
        <div class="head">
          <el-input size="medium" placeholder="请输入内容" v-model="searchData" class="input">
            <template slot="prepend">
              <el-button>
                <i class="el-icon-back" @click="setIframeSrc"></i>
              </el-button>

              <el-button>
                <i class="el-icon-right"></i>
              </el-button>
            </template>
          </el-input>
        </div>
        <iframe ref="iframe" scrolling="auto" frameborder="0" class="iframe"
                :src="src"></iframe>
      </div>
    </div>
  </div>
</template>

<script>


import { EventBus } from '@/eventBus/event-bus'
// import HelpPage from '@/pages/user/components/HelpPage.vue'

export default {
  name: 'HelpDocs',
  data() {
    return {
      searchData: '',
      searchList: [],
      helpShow: false,
      src: '',
      top: 450,
      left: 1000
    }
  },
  components: {
    // HelpPage
  },

  methods: {
    searchBtn(item) {
      console.log(item)
    },
    dragend(e) {
      e.preventDefault()//阻止元素发生默认的行为
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetTop = this.$el.getBoundingClientRect().top // container margin left
      this.left = e.clientX - offsetLeft
      this.top = e.clientY - offsetTop
    },

    setIframeSrc() {
      this.src = '//pd-h5-beta.mulaitek.com'
      this.$refs.iframe.contentWindow.location.replace(this.src)

    },
    closeBtn() {
      EventBus.$emit('helpShow', false)
    }
  },
  mounted() {
    EventBus.$on('helpShow', (msg) => {
      this.helpShow = msg
    })
    EventBus.$on('searchData', (msg) => {
      this.searchData = msg
      console.log(msg)
    })
  }
}
</script>

<style lang="scss" scoped>
.help_box {
  width: 500px;
  max-height: 60vh;
  min-height: 300px;
  position: absolute;
  z-index: 999;
  border: 1px solid rgb(235, 238, 245);
  border-radius: 4px;
  background-color: #FFFFFF;
  cursor: move;


  > * {
    padding: 16px;
  }

  .header {
    height: 20px;
    //padding: 16px;
    border-bottom: 1px solid #EBEEF5;
    margin-bottom: 16px;
    color: #303133;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;

  }

  .close {
    display: inline;
    float: right;
    color: #ff3176;
    cursor: pointer;
  }

}

.iframe {
  width: 100%;
  height: 60vh;
  min-height: 300px;

  .input {
    width: 80%;
  }
}
</style>
