import { render, staticRenderFns } from "./HelpDocs.vue?vue&type=template&id=772891f4&scoped=true"
import script from "./HelpDocs.vue?vue&type=script&lang=js"
export * from "./HelpDocs.vue?vue&type=script&lang=js"
import style0 from "./HelpDocs.vue?vue&type=style&index=0&id=772891f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "772891f4",
  null
  
)

export default component.exports